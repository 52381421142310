import { useAppSelector } from '@/redux/hooks';
import React, { useMemo } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button } from '@mui/material';
import { usePages } from '@/contexts/pages';
import { useMain } from '@/contexts/delivery/main';
import useTranslation from '@/hooks/cardapioUseTranslation';

export default function HighlightsComp() {
	const { listProducts } = useAppSelector(state => state.menuDelivery);
	const { setOpenProduct } = usePages();
	const { setCurrentProduct } = useMain();
	const { returnTranslation } = useTranslation();
	const hasDestaque = useMemo(() => !!listProducts?.conteudo?.find(product => product.destaque === true), [listProducts]);

	return (
		<section className="highlights">
			{hasDestaque && (
				<>
					<h3 className="font-semibold text-lg pb-2 pt-4" style={{}}>
						{returnTranslation('highlights')}
					</h3>
					<div>
						<Swiper slidesPerView={'auto'} spaceBetween={16} grabCursor={true} modules={[Pagination]}>
							{listProducts?.conteudo?.map(highlight => {
								if (highlight?.destaque) {
									return (
										<SwiperSlide key={highlight.id} className="highlightsItem">
											<Button
												className="highlightsBtn"
												onClick={() => {
													setCurrentProduct(highlight);
													setOpenProduct(true);
												}}>
												{highlight.imagens.map(img => {
													if (img.principal) {
														return (
															<div
																key={img.ordem}
																style={{
																	backgroundImage: `url(${img?.imagem})`,
																}}
																className="highlightsImg"></div>
														);
													}
												})}
												<p className="highlightsName">{highlight.nome}</p>
											</Button>
										</SwiperSlide>
									);
								}
							})}
						</Swiper>
					</div>
				</>
			)}
		</section>
	);
}
