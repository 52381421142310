import { CardActionArea, Card, CardMedia, CardContent, Typography, Box } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Fidelidade from '../..';
import { useAppDispatch } from '@/redux/hooks';
import { setOpenFidelidade } from '@/redux/reducers/cardapioFidelity/cardapioAuthFidelity';

type Props = {
	imgFidelidade: string;
	colorFidelidade: string;
};

export default function FidalidadeBanner({ colorFidelidade, imgFidelidade }: Props) {
	const dispatch = useAppDispatch();

	return (
		<Box sx={{ paddingRight: '1rem', paddingLeft: '1rem' }}>
			<Card sx={{ maxWidth: 'auto', background: '#f5f5f5', padding: '1vw', marginTop: '1.5vh', marginBottom: '2.5vh', boxShadow: ' 0px 4px 6px -1px rgba(0, 0, 0, 0.01)', borderColor: '#F4F3F3' }}>
				<CardActionArea sx={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '2vh', paddingRight: '2vh' }} onClick={() => dispatch(setOpenFidelidade(true))}>
					<Box sx={{ width: '42px', height: '42px', background: '#' + colorFidelidade, borderRadius: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<CardMedia component="img" height="140" image={imgFidelidade} sx={{ width: '25px' }} />
					</Box>
					<CardContent sx={{ padding: '10px' }}>
						<Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: '0', fontWeight: '600', fontSize: '1rem', paddingRight: '15vw' }}>
							{'Programa fidelidade '}
						</Typography>
						<Typography variant="body2" color="text.secondary" sx={{ fontWeight: '500' }}>
							{'Clique para saber mais!'}
						</Typography>
					</CardContent>
					<ArrowForwardIosIcon sx={{ fontSize: '1.5rem' }} />
				</CardActionArea>
			</Card>
			<Fidelidade />
		</Box>
	);
}
