import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import HighlightsComp from '../Highlights';
import BannerComp from '../Banner';
import ProductsComp from '../Products';
import LoadingPage from '../Loading';
import HeadComp from '@/components/Head';
import { NextPageContext } from 'next';
import SEO from '@/helpers/seo';
import { GetBanners, GetDepartments, GetProducts } from '@/services/delivery/menu';
import HomeDelivery from '@/pages/pedidos/components/HomeDelivery';
import FidalidadeBanner from '../Fidelidade/components/FidelidadeBanner';

export default function Menu({ seo }: any) {
	const dispatch = useAppDispatch();
	const { client } = useAppSelector(state => state.authDelivery);
	const { loading, listDepartments } = useAppSelector(state => state.menuDelivery);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [value, setValue] = useState<number | undefined>(undefined);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		if (listDepartments && listDepartments.conteudo) {
			setValue(listDepartments.conteudo[0].id);
		}
	}, [listDepartments]);

	const handleScroll = () => {
		const position = window.pageYOffset;
		const element = document.querySelectorAll('.productContainer');
		let currentId;

		element.forEach(el => {
			const id_depto = el.getAttribute('id');

			if (id_depto) {
				const elemento_id = document.getElementById(id_depto?.toString());

				if (elemento_id) {
					const offset = elemento_id.getBoundingClientRect().top;

					if (offset < 120) {
						currentId = id_depto;
					}
				}
			}
		});

		if (currentId) {
			setValue(+currentId);
		}

		setScrollPosition(position);
	};

	const scrollDown = (id: number) => {
		const element = document.getElementById(id.toString());

		if (element !== null) {
			let pos = element.offsetTop - 80;

			window.scrollTo({ top: pos, behavior: 'smooth' });
		}
	};

	useEffect(() => {
		if (client) {
			(async () => {
				await dispatch(GetBanners());
				await dispatch(GetProducts());
				await dispatch(GetDepartments());
			})();
		}
	}, [client]);

	return (
		<>
			<HomeDelivery />
			{seo && (
				<HeadComp
					title={seo.config_cliente.empresa || ''}
					img={seo.config_cliente.img_logo || ''}
					facebook={seo.config_cliente.pixel_facebook || ''}
					globalSiteId={seo.config_cliente.global_site_id || ''}
					globalSiteTag={seo.config_cliente.global_site_tag || ''}
				/>
			)}
			<section id="menu">
				{loading ? (
					<LoadingPage />
				) : (
					<>
						<BannerComp />
						<HighlightsComp />
						{client?.config_cliente?.usa_fidelidade && <FidalidadeBanner colorFidelidade={client?.config_cliente?.cor ?? 'black'} imgFidelidade={client?.config_cliente?.icone_fidelidade} />}
						<ProductsComp currentValue={value} scrollDown={scrollDown} />
					</>
				)}
			</section>
		</>
	);
}

export const getServerSideProps = async (context: NextPageContext) => {
	let seo = await SEO(context);
	return seo;
};
